<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index" @click="$router.push({ name: 'volunteerDetail', query: { id: item.id } })">
                <img class="active" :src="item.picUrl" alt="" />
                <div class="info">
                    <div class="title">{{ item.volunteerTitle || '' }}</div>
                    <div class="address">
                        <span>时间：{{ item.createTime || '' }}</span>
                    </div>
                    <div class="address">
                        <span>介绍：{{ item.briefIntroduction || '' }}</span>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination @size-change="getData" @current-change="currentChange" :current-page.sync="page" :page-sizes="[12, 24, 36, 48]" :page-size.sync="limit" layout="prev, pager, next,jumper" class="pagination" :total="total" background></el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            total: 0,
            page: 1,
            limit: 12,
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '志愿服务',
                },
                {
                    title: '志愿者风采',
                    to: { path: '/volunteerList' },
                },
            ],
        };
    },
    methods: {
        getData() {
            this.$hao.getVolunteerServiceList({ pageSize: this.limit, pageNum: this.page }).then((res) => {
                this.list = res.rows;
                this.total = res.total;
            });
        },
        currentChange(val) {
            this.page = val;
            this.getData();
        },
    },
    created() {
        this.getData();
    },
};
</script>

<style scoped lang="less">
.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 16px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        page-break-inside: avoid;
        width: 100%;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        margin-top: 24px;
        position: relative;

        .active {
            width: 100%;
            height: 210px;
        }

        .info {
            padding: 12px;

            .title {
                font-weight: 700;
                font-size: 16px;
                line-height: 23px;
                height: 46px;
                color: #333333;
                width: 100%;
                .ellipsisLine(2);
            }
            .status {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 16px;
                line-height: 23px;
                color: #ffffff;
                padding: 4px 12px;
                background: #f33f3e;
                border-radius: 2px 4px;
            }

            .address {
                display: flex;
                align-items: center;
                margin-top: 10px;
                span {
                    font-size: 13px;
                    line-height: 19px;
                    color: #999999;
                }
            }
        }
    }
}
</style>
